import { MsalAuthProvider, LoginType } from 'react-aad-msal';
 
// Msal Configurations
const config = {
  auth: {
    authority: 'https://login.microsoftonline.com/bjarkcorp.onmicrosoft.com',
    clientId: 'dbb7dacc-5559-4491-ad73-3d0a3f07ec26'
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true
  }
};
 
// Authentication Parameters
const authenticationParameters = {
  scopes: [
    "openid",
    "profile",
    'https://bjarkcorp.onmicrosoft.com/dbb7dacc-5559-4491-ad73-3d0a3f07ec26/Files.Read'
  ]
}
 
// Options
const options = {
  loginType: LoginType.Popup,
  tokenRefreshUri: window.location.origin + '/auth.html'
}
 
export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)